<template>
  <Table v-if="tournamentsIds.length">
    <template #thead>
      <TableRow>
        <TableData heading text-align="left">{{
          $t('tournaments.single')
        }}</TableData>
        <TableData heading>{{ $t('global.start') }}</TableData>
        <TableData heading>{{
          $t('global.durationShort')
        }}</TableData>
        <TableData heading>{{ $t('tournaments.system') }}</TableData>
        <TableData heading>{{ $t('participants.few') }}</TableData>
        <TableData heading>{{ $t('global.conditions') }}</TableData>
        <TableData heading>{{
          $t('tournaments.prizePool')
        }}</TableData>
        <TableData heading>{{ $t('global.status') }}</TableData>
      </TableRow>
    </template>
    <template #tbody>
      <TableRow
        v-for="tournament in tournaments"
        :key="tournament.id"
      >
        <TableData text-align="left" white-space="normal">
          <cell-tournament :id="tournament.id" />
        </TableData>
        <TableData>
          <pvp-tooltip>
            {{ $dt(tournament.tsStartRun, 'dd_MM_HH_mm') }}
            <template slot="tooltip">{{
              $t('tournaments.tooltips_start')
            }}</template>
          </pvp-tooltip>
        </TableData>
        <TableData>
          <template v-if="tournament.isHub">
            <div
              v-for="(text, key) in tournament.duration"
              :key="key"
            >
              {{ text }}
            </div>
          </template>
          <pvp-tooltip v-else>
            <template slot="tooltip">{{
              $t('tournaments.tooltips_duration')
            }}</template>
            <div
              v-for="(text, key) in tournament.duration"
              :key="key"
            >
              {{ text }}
            </div>
          </pvp-tooltip>
        </TableData>
        <TableData>
          <pvp-tooltip>
            <template slot="tooltip">
              {{
                $t('tournaments.tooltips_format_text', {
                  counts: $tc(
                    'tournaments.tooltips_format_counts',
                    tournament.numTeamMembers,
                  ),
                })
              }}
            </template>
            {{ tournament.format }}
          </pvp-tooltip>
        </TableData>
        <TableData>
          <template v-if="tournament.isHub">
            {{ tournament.participantsCount }}
          </template>
          <pvp-tooltip v-else>
            <template slot="tooltip">{{
              $t('tournaments.tooltips_participants')
            }}</template>
            {{ tournament.participantsCountStatistic }}
          </pvp-tooltip>
        </TableData>
        <TableData>
          <TournamentConditions
            :trust-tooltip-text="$t('organizations.trusted_enabled')"
            :anticheat-tooltip-text="
              $t('tournaments.tooltips_anticheat_enabled')
            "
            :is-trusted="tournament.premium"
            :is-anticheat="tournament.hasAntiCheat"
          />
        </TableData>
        <TableData>
          <div v-if="tournament.hasPrizes" class="prizes">
            <pvp-tooltip
              v-for="(prize, key) in tournament.prizes"
              :key="key"
            >
              <div class="prize">
                <div>{{ prize.count }}</div>
                <Icon :name="prize.icon" inline />
                <template slot="tooltip">{{
                  prize.tooltip
                }}</template>
              </div>
            </pvp-tooltip>
          </div>
        </TableData>
        <TableData text-align="left">
          <cell-simple>
            <slot name="status" :tournament="tournament">
              <tournament-status :id="tournament.id" />
              <div
                v-if="tournament.participationResult"
                class="participation-result"
              >
                {{ tournament.participationResult.text }}
              </div>
            </slot>
          </cell-simple>
        </TableData>
      </TableRow>
    </template>
  </Table>
</template>

<script>
import Table from '@src/components/v2/ui/Table.vue';
import TableRow from '@src/components/v2/ui/TableRow.vue';
import TableData from '@src/components/v2/ui/TableData.vue';
import TournamentConditions from '@components/v2/TournamentConditions.vue';

import TournamentStatus from '@components/TournamentComponents/Lists/TournamentStatus.vue';
import CellTournament from '@components/TableCells/CellTournament.vue';
import CellSimple from '@components/TableCells/CellSimple.vue';
import Icon from '@components/v2/utils/Icon.vue';

export default {
  name: 'TournamentsList',
  components: {
    Icon,
    Table,
    TableRow,
    TableData,
    TournamentConditions,
    CellSimple,
    CellTournament,
    TournamentStatus,
  },
  props: {
    tournamentsIds: {
      type: Array,
      required: true,
    },
    sortOrder: {
      type: String,
      default: 'desc',
    },
  },
  computed: {
    ...mapGetters('tournaments', ['getTournamentTable']),
    tournaments() {
      return this.tournamentsIds?.map(this.getTournamentTable);
    },
  },
};
</script>

<style scoped>
.prizes {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2px;
}
.prize {
  display: flex;
  align-items: center;
  gap: 4px;
}
</style>
